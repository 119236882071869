import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import otpImg from "../assets/images/Yoloh_Yolo_transparent.png";
import "./index.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Confirmed from "../assets/images/Confirmed-cuate.svg";
import {
  cognitoAttributeVerify,
  cognitoConfirmSignIn,
  cognitoCurrentUser,
  cognitoSignIn,
  cognitoSignUp,
  cognitoUpdateUserAttributes,
} from "../http/services/cognito.service";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { AuthContext, useAuth } from "../context/AuthContext";
import { Controller, useForm } from "react-hook-form";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MuiCheckbox from "@mui/material/Checkbox";
import { handleEmailValidate, handleMobileValidate } from "../validations";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useLocalStorage } from "../http/services/local-storage";
import { getAgentProfileDetailsPayloadTemplate } from "../http/services/api-payload-prepare";
import { getAgentProfileApi } from "../http/services/user.service";
import { enqueueSnackbar } from "notistack";
import { environment } from "../environment/environment";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { handleKeyPressSpace } from "../constants/restrictSpace";
import { getLocalStorageValue } from "../http/services/local-storage-service";

const Login = ({ setLoginModal }) => {
  const navigate = useNavigate();
  const { setItem } = useLocalStorage();
  const { handleSubmit, reset, control } = useForm();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [showKey, setShowKey] = useState("LOGIN");
  const [registerFlowStarted, setRegisterFlowStarted] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const { login } = useAuth();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [termAndCondition, setTermsAndCondition] = useState(false);
  const [value, setValue] = useState(0);
  const [countryPrefixes] = useState(
    JSON.parse(environment.country_prefix.available_prefix)
  );
  const [isCountryPrefixDisabled] = useState(
    environment.country_prefix.is_prefix_disabled === "false"
  );
  const [currentCountryPrefix, setCurrentCountryPrefix] = useState(
    environment.country_prefix.default_prefix
  );
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(90);

  // const defaultPrefix = useMemo(() => {
  //   return environment.country_prefix.default_prefix === "+1";
  // }, [environment]);

  const resetLoginForm = () => {
    reset({
      phone_number: "",
      email: "",
    });
    setUsername("");
    setEmail("");
    setOtp("");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    resetLoginForm();
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const validateChar = (value, index) => {
    const NUMERIC_PLUS_REGEX = /^[0-9]+$/;
    return value ? NUMERIC_PLUS_REGEX.test(value) : true;
  };

  const setUsernameFieldValue = (event) => {
    setUsername(event.target.value);
  };

  const setEmailFieldValue = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const { authUser, setAuthUser } = useContext(AuthContext);
  const checkAgentStatus = () => {
    const payload = getAgentProfileDetailsPayloadTemplate();
    getAgentProfileApi(payload)
      .then((response) => {
        setAuthUser({ ...authUser, profileDetails: response?.profile });
        setItem("cognito_uid", response?.profile?.cognito_uid);
        setItem("firstName", response?.profile?.first_name);
        setItem("lastName", response?.profile?.last_name);
        setItem("agentProfileData", JSON.stringify(response?.profile));
        setItem("agentProfileDetails", JSON.stringify(response));
        if (response?.profile?.agent_verification_status === "pending") {
          navigate("/");
          return;
        } else if (
          response?.agentLicense?.find(
            (item) => item.license_verification_status === "reject"
          )
        ) {
          navigate("/dashboard");
          return;
        }
      })
      .catch(() => {});
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setRequestInProgress(true);
      let usernameCalculated =
        value === 0 ? currentCountryPrefix + username : username;
      cognitoSignIn(usernameCalculated, value)
        .then((res) => {
          setCognitoUser(res);
          setRegisterFlowStarted(false);
          setShowKey("OTP");
          setSeconds(90);
        })
        .catch((err) => {
          let errData = err;
          if (
            errData.code === "UserLambdaValidationException" &&
            errData.message.includes("phone_number already exists")
          ) {
            setError("Phone number is already exist, please login.!");
            setTimeout(() => {
              setError(null);
            }, 3000);
          } else if (
            errData.code === "UserLambdaValidationException" &&
            errData.message.includes("user does not exists")
          ) {
            setError("User does not exists, please signup.!");
            setTimeout(() => {
              setError(null);
            }, 3000);
          } else {
            setError(errData.message);
            setTimeout(() => {
              setError(null);
            }, 3000);
          }
        })
        .finally(() => {
          setRequestInProgress(false);
        });
    }
  };

  return (
    <div className="popupBg">
      <div className="logImgMain">
        <div className="popupContain">
          <img width="200px" alt="Angular Logo" src={logo} />
          <img
            alt="Angular Logo"
            className="illu"
            src={
              showKey !== "ENTEREMAIL" || showKey !== "EMAIlOTP"
                ? otpImg
                : Confirmed
            }
          />
        </div>
      </div>
      <IconButton
        aria-label="delete"
        classes={{ root: "closeLoginModalButton" }}
        onClick={() => {
          if (!requestInProgress) {
            setLoginModal(false);
          }
        }}
      >
        <CloseOutlinedIcon />
      </IconButton>

      {/* LOGIN  */}
      {showKey === "LOGIN" && (
        <div className="formContainMain h-100">
          <div className="overflow">
            <h1 className="res-modal-title">Log in to your account</h1>
            {/* <p>Hello, Welcome back to account!</p> */}
            <div className="tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                color="primary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                TabIndicatorProps={{
                  style: { backgroundColor: "#09143A" },
                }}
              >
                <Tab
                  label="Phone Number"
                  {...a11yProps(0)}
                  style={{ fontFamily: "Jokker-semibold" }}
                />
                <Tab
                  label="Email Id"
                  {...a11yProps(1)}
                  style={{ fontFamily: "Jokker-semibold" }}
                />
              </Tabs>

              {value === 0 && (
                <div
                  className="mt-20"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <FormControl
                    style={{
                      width: "25%",
                      marginRight: "10px",
                      marginBottom: "-10px",
                    }}
                  >
                    <InputLabel id="prefix-login-label">Code</InputLabel>
                    <Select
                      disabled={isCountryPrefixDisabled}
                      labelId="prefix-login-label"
                      id="prefix-login-label-select"
                      label="Prefix"
                      value={currentCountryPrefix}
                      onChange={(event) => {
                        if (!isCountryPrefixDisabled) {
                          setCurrentCountryPrefix(event.target.value);
                        }
                      }}
                    >
                      {countryPrefixes.map((code) => (
                        <MenuItem value={code} key={code}>
                          {code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{
                      required: "Phone Number is required",
                      validate: (value) =>
                        handleMobileValidate(value)
                          ? null
                          : "Please enter valid phone number",
                    }}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        id="phone_number"
                        name="phone_number"
                        label="Phone Number"
                        variant="outlined"
                        margin="normal"
                        color="secondary"
                        type="text"
                        value={value}
                        error={!!error}
                        placeholder="Enter your phone number"
                        autoComplete="off"
                        onChange={(event) => {
                          const newValue = event.target.value;
                          if (
                            /^[0-9\+]*$/.test(newValue) &&
                            newValue?.length < 14
                          ) {
                            onChange(event);
                            setUsernameFieldValue(event);
                          }
                          setUsernameFieldValue(event);
                        }}
                        onKeyDown={(event) => handleKeyPress(event)}
                        fullWidth
                        InputLabelProps={{
                          classes: { focused: "hello-world", root: "world" },
                        }}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </div>
              )}
              {value === 1 && (
                <div className="mt-20">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email Id is required",
                      validate: (value) =>
                        handleEmailValidate(value)
                          ? null
                          : "Please enter valid email",
                    }}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        id="email"
                        name="email"
                        label="Email Id"
                        type="email"
                        variant="outlined"
                        margin="normal"
                        color="secondary"
                        value={value}
                        error={!!error}
                        placeholder="Enter your email id"
                        autoComplete="off"
                        onChange={(event) => {
                          onChange(event);
                          setUsernameFieldValue(event);
                        }}
                        fullWidth
                        onKeyDown={(event) => handleKeyPress(event)}
                        inputProps={{
                          tabIndex: 1,
                        }}
                        InputLabelProps={{
                          classes: { focused: "hello-world", root: "world" },
                        }}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </div>
              )}
              {error && <p className="error_block">{error}</p>}
              {requestInProgress && (
                <p className="please_wait_block">
                  Please wait &nbsp; &nbsp;{" "}
                  <CircularProgress color="inherit" size={30} />
                </p>
              )}

              {!requestInProgress && (
                <button
                  className="otp-button"
                  disabled={!username}
                  tabIndex={3}
                  onClick={handleSubmit(() => {
                    setRequestInProgress(true);
                    let usernameCalculated =
                      value === 0 ? currentCountryPrefix + username : username;
                    cognitoSignIn(usernameCalculated, value)
                      .then((res) => {
                        setCognitoUser(res);
                        setRegisterFlowStarted(false);
                        setShowKey("OTP");
                        setSeconds(90);
                      })
                      .catch((err) => {
                        let errData = err;
                        if (
                          errData.code === "UserLambdaValidationException" &&
                          errData.message.includes(
                            "phone_number already exists"
                          )
                        ) {
                          setError(
                            "Phone number is already exist, please login.!"
                          );
                          setTimeout(() => {
                            setError(null);
                          }, 3000);
                        } else if (
                          errData.code === "UserLambdaValidationException" &&
                          errData.message.includes("user does not exists")
                        ) {
                          setError("User does not exists, please signup.!");
                          setTimeout(() => {
                            setError(null);
                          }, 3000);
                        } else {
                          setError(errData.message);
                          setTimeout(() => {
                            setError(null);
                          }, 3000);
                        }
                      })
                      .finally(() => {
                        setRequestInProgress(false);
                      });
                  })}
                >
                  Get One Time Passcode (OTP)
                </button>
              )}
              <h3 className="account-link">
                New to YOLOH ?{" "}
                <a
                  onClick={() => {
                    if (!requestInProgress) {
                      if (value === 0) {
                        setUsername(username);
                      } else {
                        setEmail(username);
                      }
                      setShowKey("CREATE");
                      setValue(0);
                    }
                  }}
                  className="span-primary"
                >
                  Sign Up
                </a>
              </h3>
            </div>
          </div>
        </div>
      )}
      {/* OTP VERIFICATION */}
      {showKey === "OTP" && (
        <div className="formContainMain h-100">
          <div className="overflow">
            <h1>Verification Code</h1>
            <p>
              Please enter the One time Passcode sent to{" "}
              <strong>
                {value === 0 ? currentCountryPrefix + username : username}
              </strong>
            </p>
            <div className="otp-section">
              <div className="OtpFildMain">
                <MuiOtpInput
                  className="otp-box-res"
                  autoFocus
                  value={otp}
                  length={6}
                  validateChar={validateChar}
                  onChange={(newValue) => {
                    if (!requestInProgress) {
                      setOtp(newValue);
                    }
                  }}
                />
              </div>
              {error && <p className="error_block">{error}</p>}
              {requestInProgress && (
                <p className="please_wait_block">
                  Please wait &nbsp; &nbsp;{" "}
                  <CircularProgress color="inherit" size={30} />
                </p>
              )}

              {!requestInProgress && (
                <>
                  <h3 className="resend">
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        Time Remaining To Resend <br /> One Time Passcode (OTP)
                        :{minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <>
                        <p>
                          Didn't receive code? <br />
                          <a
                            href="#"
                            onClick={() => {
                              setRequestInProgress(true);
                              setSeconds(90);
                              let usernameCalculated =
                                value == 0
                                  ? currentCountryPrefix + username
                                  : username;
                              cognitoSignIn(usernameCalculated, value)
                                .then((res) => {
                                  setCognitoUser(res);
                                })
                                .finally(() => {
                                  setRequestInProgress(false);
                                });
                            }}
                            className="span-primary"
                          >
                            Resend One Time Passcode (OTP)
                          </a>
                        </p>
                      </>
                    )}
                  </h3>
                  <button
                    className="otpButton"
                    disabled={!otp || otp.length < 6}
                    onClick={() => {
                      setRequestInProgress(true);
                      cognitoConfirmSignIn(cognitoUser, otp)
                        .then(async (user) => {
                          setSeconds(0);
                          setRequestInProgress(true);
                          if (user.attributes && user.attributes.phone_number) {
                            if (registerFlowStarted) {
                              setOtp("");
                              setSeconds(90);
                              setShowKey("EMAILOTP");
                              cognitoUpdateUserAttributes(cognitoUser, {
                                email: email,
                              })
                                .then((res) => {})
                                .finally(() => {
                                  setRequestInProgress(false);
                                });
                            } else {
                              if (user.attributes.email_verified == false) {
                                setShowKey("ENTEREMAIL");
                                setRequestInProgress(false);
                              } else if (
                                !(
                                  user?.attributes?.email &&
                                  user?.attributes?.email?.length > 0
                                )
                              ) {
                                setShowKey("ENTEREMAIL");
                                setRequestInProgress(false);
                              } else {
                                await login(user);

                                checkAgentStatus();

                                if (
                                  getLocalStorageValue("authUserValidated") ==
                                  false
                                ) {
                                  enqueueSnackbar("Please Complete Profile.", {
                                    variant: "warning",
                                  });
                                  // navigate({
                                  //   pathname: "/create-account",
                                  //   search: `?cognitoId=${getLocalStorageValue(
                                  //     "authCognitoId"
                                  //   )}&emailId=${
                                  //     JSON.parse(
                                  //       getLocalStorageValue("authUser")
                                  //     )?.attributes?.email
                                  //   }`,
                                  // });
                                  navigate("/create-account", {
                                    state: {
                                      cognitoId:
                                        getLocalStorageValue("authCognitoId"),
                                      emailId: JSON.parse(
                                        getLocalStorageValue("authUser")
                                      )?.attributes?.email,
                                    },
                                  });
                                } else if (
                                  getLocalStorageValue("authUserValidated") ==
                                  true
                                ) {
                                  navigate("/dashboard");
                                } else {
                                  navigate("/");
                                }
                                setLoginModal(false);
                                setRequestInProgress(false);
                                setOtp("");
                                setEmail("");
                                setShowKey("LOGIN");
                              }
                            }
                          } else {
                            setError("Invalid One time Passcode!");
                            setOtp("");
                            setTimeout(() => {
                              setError(null);
                            }, 5000);
                            setRequestInProgress(false);
                          }
                        })
                        .catch((err) => {
                          setError("Please try to login again");
                          resetLoginForm();
                          setShowKey("LOGIN");
                          setTimeout(() => {
                            setError(null);
                          }, 5000);
                          setRequestInProgress(false);
                        });
                    }}
                  >
                    Verify One time Passcode
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {showKey === "ENTEREMAIL" && (
        <div className="formContainMain h-100">
          <div className="overflow">
            <h1>Enter Your Email</h1>
            <p>Please enter your Email to receive the one-time password</p>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required!",
                validate: (value) =>
                  handleEmailValidate(value)
                    ? null
                    : "Please enter valid email!",
              }}
              defaultValue={email}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="email"
                  name="email"
                  type="email"
                  label="Email Id"
                  variant="outlined"
                  margin="normal"
                  color="secondary"
                  value={value}
                  error={!!error}
                  placeholder="Enter your email id"
                  onChange={(event) => {
                    onChange(event);
                    setEmailFieldValue(event);
                  }}
                  fullWidth
                  inputProps={{ tabIndex: 1, onKeyPress: handleKeyPressSpace }}
                  InputLabelProps={{
                    classes: { focused: "hello-world", root: "world" },
                  }}
                  helperText={error ? error.message : null}
                />
              )}
            />
            {requestInProgress && (
              <p className="please_wait_block">
                Please wait &nbsp; &nbsp;{" "}
                <CircularProgress color="inherit" size={30} />
              </p>
            )}

            {!requestInProgress && (
              <button
                className="otp-button mt-156"
                disabled={!email}
                onClick={handleSubmit(() => {
                  setRequestInProgress(true);
                  cognitoUpdateUserAttributes(cognitoUser, { email: email })
                    .then((res) => {
                      setRequestInProgress(false);
                      setOtp("");
                      setShowKey("EMAILOTP");
                      setSeconds(90);
                    })
                    .finally(() => {
                      setRequestInProgress(false);
                    });
                })}
              >
                Get One Time Passcode (OTP)
              </button>
            )}
          </div>
        </div>
      )}
      {showKey === "EMAILOTP" && (
        <div className="formContainMain h-100" style={{ position: "relative" }}>
          <div className="overflow">
            {/* <div className="formContainMain" > */}
            <h1> Verification Code</h1>
            <p>
              Please enter the One time Passcode sent to{" "}
              <strong>{email}</strong>
            </p>
            <div className="otp-section">
              <div className="OtpFildMain">
                <MuiOtpInput
                  autoFocus
                  value={otp}
                  length={6}
                  validateChar={validateChar}
                  onChange={(newValue) => {
                    if (!requestInProgress) {
                      setOtp(newValue);
                    }
                  }}
                />
              </div>

              {error && <p className="error_block">{error}</p>}

              {requestInProgress && (
                <p className="please_wait_block">
                  Please wait &nbsp; &nbsp;{" "}
                  <CircularProgress color="inherit" size={30} />
                </p>
              )}

              {!requestInProgress && (
                <>
                  <h3 className="resend">
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        Time Remaining To Resend <br /> One Time Passcode (OTP)
                        :{minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <>
                        <p>
                          Didn't receive code? <br />
                          <a
                            href="#"
                            onClick={() => {
                              setRequestInProgress(true);
                              setSeconds(90);
                              cognitoUpdateUserAttributes(cognitoUser, {
                                email: email,
                              })
                                .then((res) => {
                                  cognitoCurrentUser().then((user) => {
                                    // setCognitoUser(user)
                                  });
                                })
                                .finally(() => {
                                  setRequestInProgress(false);
                                });
                            }}
                            className="span-primary"
                          >
                            Resend One Time Passcode (OTP)
                          </a>
                        </p>
                      </>
                    )}
                  </h3>
                  <button
                    className="otpButton"
                    onClick={() => {
                      setRequestInProgress(true);
                      cognitoAttributeVerify(cognitoUser, "email", otp)
                        .then((res) => {
                          cognitoCurrentUser().then(async (res) => {
                            await login(res);
                            setLoginModal(false);
                            setRequestInProgress(false);
                            setOtp("");
                            setEmail("");
                            setShowKey("LOGIN");
                            enqueueSnackbar("Please Complete Profile.", {
                              variant: "warning",
                            });
                            // navigate({
                            //   pathname: "/create-account",
                            //   search: `?cognitoId=${getLocalStorageValue(
                            //     "authCognitoId"
                            //   )}&emailId=${email}`,
                            // });
                            navigate("/create-account", {
                              state: {
                                cognitoId:
                                  getLocalStorageValue("authCognitoId"),
                                emailId: email,
                              },
                            });
                            // navigate(0);
                          });
                        })
                        .catch((error) => {
                          enqueueSnackbar(error, {
                            variant: "error",
                          });
                          setRequestInProgress(false);
                          setError(
                            error?.message !==
                              "An account with the email already exists." &&
                              "Invalid One time Passcode!"
                          );
                          setOtp("");
                          setTimeout(() => {
                            setError(null);
                          }, 5000);
                        });
                    }}
                  >
                    Verify One time Passcode
                  </button>
                </>
              )}

              <div className="backBtn" onClick={() => setShowKey("ENTEREMAIL")}>
                <ArrowBackIosNewIcon />
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
      {/* CREATE ACCOUNT */}
      {showKey === "CREATE" && (
        <div className="formContainMain h-100">
          <div className="overflow">
            <h1 className="res-create-modal-title">Create Account</h1>
            <p>
              Please enter your Phone Number and Email to create an account.
            </p>
            <div className="mt-50">
              <div
                className="verify-account"
                style={{ gap: "10px", width: "100%", display: "flex" }}
              >
                <FormControl style={{ width: "30%" }}>
                  <InputLabel id="prefix-register-label">Code</InputLabel>
                  <Select
                    disabled={isCountryPrefixDisabled}
                    labelId="prefix-register-label"
                    id="prefix-register-label-select"
                    label="Prefix"
                    value={currentCountryPrefix}
                    onChange={(event) => {
                      if (!isCountryPrefixDisabled) {
                        setCurrentCountryPrefix(event.target.value);
                      }
                    }}
                  >
                    {countryPrefixes.map((code) => (
                      <MenuItem value={code} key={code}>
                        {code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Controller
                  name="phone_number"
                  control={control}
                  rules={{
                    required: "Phone Number is required",
                    validate: (value) =>
                      handleMobileValidate(value)
                        ? null
                        : "Please enter valid Phone number",
                  }}
                  defaultValue={username}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      style={{ width: "75%", margin: 0 }}
                      id="phone_number"
                      name="phone_number"
                      label="Phone Number"
                      variant="outlined"
                      margin="normal"
                      color="secondary"
                      type="text"
                      value={value}
                      error={!!error}
                      placeholder="Enter your phone number"
                      autoComplete="off"
                      onKeyDown={(event) => {
                        const NUMERIC_PLUS_REGEX = /^[0-9]+$/;
                        if (
                          !NUMERIC_PLUS_REGEX.test(event.key) &&
                          !event.ctrlKey &&
                          event.code &&
                          event.code !== "Backspace" &&
                          !event.code.includes("Arrow") &&
                          !event.code.includes("Delete") &&
                          event.code !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onBlur={handleSubmit(() => {})}
                      onChange={(event) => {
                        onChange(event);
                        setUsernameFieldValue(event);
                      }}
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      InputLabelProps={{
                        classes: { focused: "hello-world", root: "world" },
                      }}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </div>
              <div className="verify-account">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email Address is required",
                    validate: (value) =>
                      handleEmailValidate(value)
                        ? null
                        : "Please enter valid email",
                  }}
                  defaultValue={email}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      id="email"
                      name="email"
                      type="email"
                      label="Email Id"
                      variant="outlined"
                      margin="normal"
                      color="secondary"
                      value={value}
                      error={!!error}
                      placeholder="Enter your email id"
                      autoComplete="off"
                      onChange={(event) => {
                        onChange(event);
                        setEmailFieldValue(event);
                      }}
                      fullWidth
                      inputProps={{ tabIndex: 1, onKeyPress: handleKeyPress }}
                      InputLabelProps={{
                        classes: { focused: "hello-world", root: "world" },
                      }}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </div>
              {error && <p className="error_block">{error}</p>}

              {requestInProgress && (
                <p className="please_wait_block">
                  Please wait &nbsp; &nbsp;{" "}
                  <CircularProgress color="inherit" size={30} />
                </p>
              )}
              {!requestInProgress && (
                <>
                  <div className="d-flex gap-17">
                    <MuiCheckbox
                      checked={termAndCondition}
                      onChange={(value) => {
                        setError(null);
                        setTermsAndCondition(value.target.checked);
                      }}
                      label="CheckCircleIcon"
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                    <p className="font-14 mt-20 mb-20">
                      I accept the
                      <a href="/terms" target="_blank">
                        <strong>&nbsp;Terms of use</strong>
                      </a>
                      &nbsp; and
                      <a href="/privacy-policy" target="_blank">
                        <strong>&nbsp;Privacy policy</strong>
                      </a>
                    </p>
                  </div>
                  <p className="mb-20 mt-0" style={{ fontSize: "12px" }}>
                    By creating an account, you are agreeing to the YOLOH terms
                    and conditions and YOLOH privacy policy. Message and data
                    rates may apply when registering with a phone number.
                  </p>
                  <button
                    className="otpButton"
                    onClick={handleSubmit(() => {
                      if (termAndCondition && !requestInProgress) {
                        setRequestInProgress(true);
                        setRegisterFlowStarted(true);
                        cognitoSignUp(currentCountryPrefix + username)
                          .then((res) => {
                            cognitoSignIn(
                              currentCountryPrefix + username,
                              value
                            )
                              .then((res) => {
                                setCognitoUser(res);
                                setRequestInProgress(false);
                                setShowKey("OTP");
                                setSeconds(90);
                              })
                              .catch((err) => {
                                setRequestInProgress(false);
                                setRegisterFlowStarted(false);
                                let errData = err;
                                if (
                                  errData.code ==
                                    "UserLambdaValidationException" &&
                                  errData.message.includes(
                                    "phone_number already exists"
                                  )
                                ) {
                                  setError(
                                    "Phone number is already exist, please login.!"
                                  );
                                  setTimeout(() => {
                                    setError(null);
                                  }, 3000);
                                } else if (
                                  errData.code ==
                                    "UserLambdaValidationException" &&
                                  errData.message.includes(
                                    "user does not exists"
                                  )
                                ) {
                                  setError(
                                    "User does not exists, please signup.!"
                                  );
                                  setTimeout(() => {
                                    setError(null);
                                  }, 3000);
                                } else {
                                  setError(errData.message);
                                  setTimeout(() => {
                                    setError(null);
                                  }, 3000);
                                }
                              });
                          })
                          .catch((err) => {
                            setRequestInProgress(false);
                            setRegisterFlowStarted(false);
                            let errData = err;
                            if (
                              errData.code == "UserLambdaValidationException" &&
                              errData.message.includes(
                                "phone_number already exists"
                              )
                            ) {
                              setError(
                                "Phone number is already exist, please login.!"
                              );
                              setTimeout(() => {
                                setError(null);
                              }, 3000);
                            } else if (
                              errData.code == "UserLambdaValidationException" &&
                              errData.message.includes("user does not exists")
                            ) {
                              setError("User does not exists, please signup.!");
                              setTimeout(() => {
                                setError(null);
                              }, 3000);
                            } else {
                              setError(errData.message);
                              setTimeout(() => {
                                setError(null);
                              }, 3000);
                            }
                          });
                      } else {
                        setError("You must agree to term & condition !");
                        setTimeout(() => {
                          setError(null);
                        }, 3000);
                      }
                    })}
                  >
                    {/* Sign Up */}
                    Create Account
                  </button>
                </>
              )}

              <h3 className="account-link">
                Already have an Account?{" "}
                <a onClick={() => setShowKey("LOGIN")} className="span-primary">
                  Sign In
                </a>{" "}
                now
              </h3>
            </div>
          </div>
        </div>
      )}
      {requestInProgress && (
        <Stack
          sx={{
            width: "100%",
            color: "#fcbb18",
            position: "absolute",
            bottom: 0,
          }}
          spacing={2}
        >
          <LinearProgress color="inherit" />
        </Stack>
      )}
    </div>
  );
};

export default Login;
